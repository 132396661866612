<template>
<div>
  <v-navigation-drawer 
    class="drawer_admin"
    :touchless="true"
    clipped
    :persistent="true"
    hide-overlay
    width='15%' 
    v-model="drawer" 
    :color="color" 
    :expand-on-hover="expandOnHover" 
    :mini-variant="miniVariant" 
    :mini-variant-width="'10%'" 
    :right="right" 
    :src="bg" 
    absolute 
    dark>
    <v-layout 
      column 
      class="my-fill-height">
      <v-container>
        <v-layout 
          row 
          justify-center
          wrap>
          <img 
          class="company_logo" 
          src="/static/image_logo_white.png">
        </v-layout>
      </v-container>
      <v-divider></v-divider>
      <v-layout column>
        <v-list
         dense>
          <v-list-item
           elevation ="0"
           active-class="light-blue darken-4"
           v-for="(item, index) in items"
           :key="item.to"
           :to="item.to"
         >
            <v-list-item-icon>
              <v-tooltip
               bottom>
                <template
                 v-slot:activator="{ on }"
               >
                  <span
                   v-on="on">
                    <div>
                      <v-icon
                       :color="'white'"
                       v-html="item.icon"
                     />
                    </div>
                  </span>
                </template>
                <span
                 v-html="item.title"
               />
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
             :style="'color: white'"
             class="primaryText--text menu-text"
             v-text="item.title"
           />
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-spacer></v-spacer>
        <v-list 
          class="logout-tile set_cursor">
          <v-list-item>
            <v-list-item-icon>
              <v-tooltip 
              bottom>
                <template 
                  v-slot:activator="{ on }">
                  <span 
                  v-on="on">
                    <div>
                      <v-icon 
                        @click="logout" 
                        :color="'white'" 
                        v-html="'mdi-logout'" />
                    </div>
                  </span>
                </template>
                <span 
                  v-html="'Cerrar sesión'" />
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title 
                @click="logout"
                class="logout-tile" 
                :style="'white'" 
                v-text="'Cerrar sesión'" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-layout>

  </v-navigation-drawer>
</div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "General", 
          icon: "mdi-format-align-center", 
          to: "/admin/general" 
        },
        {
          title: "About", 
          icon: "mdi-account-settings", 
          to: "/admin/about" 
        },
        {
          title: "Proyectos", 
          icon: "mdi-domain", 
          to: "/admin/projects" 
        },
        {
          title: "Buzón", 
          icon: "mdi-email", 
          to: "/admin/contacts" 
        }
      ],
      drawer: true,
      color: 'primary',
      right: false,
      miniVariant: false,
      expandOnHover: false,
      background: false,
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
        .then(resp => {
          this.$store.commit('toggle_alert', resp)
          this.$router.go()
        })
        .catch(err => {
          this.$store.commit('toggle_alert', err)
        })
    }
  },
  computed: {
    bg() {
      return this.background ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg' : undefined
    },
  },
}
</script>

<style scoped>
.drawer_admin {
  position: fixed;
}

.v-navigation-drawer {
-webkit-box-shadow: 28px -1px 34px -34px rgba(0,0,0,0.75);
-moz-box-shadow: 28px -1px 34px -34px rgba(0,0,0,0.75);
box-shadow: 28px -1px 34px -34px rgba(0,0,0,0.75);
}

.logout-tile {
  color: white;
  font-size: 12px
}

.v-divider {
  background-color: #b1b1b120
}

.set_cursor {
  cursor: pointer;
}

.my-fill-height {
  height: 100vh;
}

.company_name {
  cursor: default;
  letter-spacing: 2px;
  margin: 20px;
  z-index: 1;
  font-family: 'Aleo', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 20px;
  max-width: 150px
}

.company_name_mobile {
  cursor: default;
  letter-spacing: 2px;
  margin: 20px;
  z-index: 1;
  font-family: 'Aleo', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 15px;
  max-width: 150px
}

.company_logo {
  height: 25%;
  width: 25%
}
</style>
